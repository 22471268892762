<template>
    <b-card>
        <app-view-table :title="trans('employee',5)" :loading="loading_list" :isTitleTotal="true" :filters.sync="filters" :items="items" v-on:onRowSelected="getProfile" :customColumns="customColumns" :canUserEdit="user_role < 3" :fields="fields" :filterOptions="user_status_list" :isSearch="true" :isFilterOptions="true" class="table-selectable">
            
                <template v-slot:phase="cell">
                    <span v-if="cell.data.item.type_name == 'Loondienst' || cell.data.item.type_name == 'Student'">{{cell.data.item.phase}}</span>
                </template>

            <template v-slot:menu-items="cell">
                <b-dropdown-item @click="changeStatus(cell.data.item, 1002)" v-if="cell.data.item.id_status_const_type != 1002">{{trans('unsubscribe', 174)}}</b-dropdown-item>
                <b-dropdown-item @click="changeStatus(cell.data.item, 100)" v-if="cell.data.item.id_status_const_type == 1002">{{trans('fl-reactivate-person', 174)}}</b-dropdown-item>
            </template>

        </app-view-table> 
        
    </b-card>  
</template>
<script>

import axios from "@axios";
import UserSettings from '@core/scripts/UserSettings';
import AppAlerts from '@core/scripts/AppAlerts';

export default {

    mounted() {
        if(this.$route.params.status != null){
            this.changeFilters(this.$route.params.status);
        }else{
            this.onCreateThis();
        }
    },

    data() {
        return {
            loading_list: false,
            filters: {
                sorting: {
                        field_name: null,
                        sort_direction: 0,
                        changed: 0
                },
                filtering: {
                        functions: {type: 'list', changed: 0, values: [], function: "getFunctions", parameters: null, widthClass:'app-width-min-350'},
                        locations: {type: 'list',  changed: 0, values: [], function: "getLocations", parameters: null, widthClass:'app-width-min-250'},
                        types: {type: 'list', changed: 0, values: [], function: "getApplicantTypes", parameters: null, widthClass:'app-width-min-250'},
                        statuses: { type: 'list', changed: 0, values: [], function: "getApplicantStatuses", parameters: null, widthClass:'app-width-min-250'},
                        phases: { type: 'list', changed: 0, values: [], function: "getPhases", parameters: null, widthClass:'app-width-min-250'},
                },
                page: 1,
                per_page: 10,
                sortBy: "p.id",
                sortDesc: true,
                search: "",
                list_type: 1,
                changed: 0
            },
            fields: [
                { key: "person_name", label: this.trans('full-name',3), thStyle: { "min-width": "90px", width: "100%" }, visible: true, filtering: false },
                { key: "function_name", label: this.trans('function',3), thStyle: { "min-width": "200px", "max-width": "200px" }, visible: true, filtering: true, filtering_name: "functions" },
                { key: "location_name", label: this.trans('branch',182), thStyle: { "min-width": "200px", "max-width": "200px" }, visible: true, filtering: true, filtering_name: "locations" },
                { key: "type_name", label: this.trans('agreement',3), thStyle: { "min-width": "200px", "max-width": "200px" }, visible: true, filtering: true, filtering_name: "types" },
                { key: "phase", label: this.trans('phase',3), thStyle: { "min-width": "150px", "max-width": "200px" }, visible: true, filtering: true, filtering_name: "phases" },
                { key: "status_name", label: this.trans('status',3), thStyle: { "min-width": "150px", "max-width": "200px" }, visible: true, filtering: true, filtering_name: "statuses" },
                { key: "more", label: "", tdStyle: "app-more-column-size", tdClass: "p-0",  filtering: false },
            ],
            items: [],
            user_status_list: [
                { value: 1, name: this.trans('my-locations',179) }, 
                { value: 2, name: this.trans('my-flex',179) },
                { value: 3, name: this.trans('fl-all-active-workers',179) }, 
                { value: 4, name: this.trans('fl-all-inactive-workers',179) }, 
                { value: 5, name: this.trans('fl-deregistired',179) },  
                
            ],
            userSettings: new UserSettings(),
            alertClass: new AppAlerts(),
            customColumns: ["phase"],
            user_role: JSON.parse(localStorage.getItem('user')).id_user_const_role,
        };
    },

    methods: {
        getList: function() {
            this.loading_list = true;
            axios
                .post("people/employee/getList", {
                    filters: JSON.stringify(this.filters),
                })
                .then((res) => {
                   this.filters.page = res.data.page
                   this.items = res.data.items;
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                }).finally(() => {
                    this.loading_list = false;
                });
        },

        changeFilters(status){
            this.filters.filtering.statuses.values = [status];
            this.getList();
        },

        changeStatus(data, id_status_const_type){
            if(id_status_const_type == 1002){
                var message = this.trans('fl-unsubscribe-confirmation',177,{person_name_message:data.person_name_message});
            } else if(id_status_const_type == 100){
                if(data.id_status_const_type == 1002){
                    var message = this.trans('fl-reactivate-person-cornfirm-allert',177,{person_name_message:data.person_name_message});
                }
            }
            this.alertClass.openAlertConfirmation(message).then(res => {
                if(res == true){
                    this.loading_list = true;
                    axios
                        .post("people/employee/changeStatus", {
                            id_person_data_main: data.id_person_data_main,
                            id_status_const_type: id_status_const_type,
                            filters: JSON.stringify(this.filters),
                        })
                        .then((res) => {
                        this.filters.page = res.data.page
                        this.items = res.data.items;
                        })
                        .catch((error) => {
                            console.error(`error during request: ${error}`);
                        }).finally(() => {
                            this.loading_list = false;
                        });
                }     
            }); 
        },

        getProfile: function(items) {
            if(items.length != 0 && items[0].id_status_const_type != 1){
                this.$router.push({ name: "people-person-profile", params: { id_person_data_main: items[0].id_person_data_main } });
            }
        },

        saveSettings() {
            this.userSettings.saveSettings(this.filters, 4);
        },

        async loadSettings() {
            var getData = JSON.parse(localStorage.getItem('userSettings'));

            if(getData == undefined || getData.employeeList == undefined){
                await this.userSettings.saveSettings(this.filters, 4);
                getData = JSON.parse(await localStorage.getItem('userSettings'));
            }
                
            var sFilters = getData.employeeList;

            this.filters.list_type = sFilters.list_type;
            this.filters.filtering = sFilters.filtering;
            this.filters.sorting = sFilters.sorting;
            this.filters.per_page = sFilters.per_page != undefined ? sFilters.per_page : 10;  
        },

        onCreateThis() { 
            this.loadSettings();

            this.getList();

            this.$root.$on('locationsChanged', () => {
        
                if (!this.loading_list) {
                    this.getList();
                } 
            });
        
            this.$watch('filters.list_type', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                    this.saveSettings()
                } 
            });

            this.$watch('filters.sorting.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                    this.saveSettings()
                } 
            });

            this.$watch('filters.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                    this.saveSettings()
                } 
            });

            this.$watch('filters.filtering.functions.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                    this.saveSettings()
                } 
            });

            this.$watch('filters.filtering.phases.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                    this.saveSettings()
                } 
            });

            this.$watch('filters.filtering.locations.changed', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                    this.saveSettings()
                } 
            });

            this.$watch('filters.filtering.types.changed', function(newVal, oldVal) {
                if (!this.cLoadList) {
                    this.getList();
                    this.saveSettings()
                } 
            });

            this.$watch('filters.filtering.statuses.changed', function(newVal, oldVal) {
                if (!this.cLoadList) {
                    this.getList();
                    this.saveSettings()
                } 
            });

            this.$watch('filters.search', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.filters.page = 1;
                    this.getList();
                } 
            });

           
            this.$watch('items.refresh', function(newVal, oldVal) {
                if (newVal > 0 && !this.loading_list) {
                    this.getList();
                }   
            });

            this.$watch('filters.page', function(newVal, oldVal) {
                if (!this.loading_list) {
                    this.getList();
                }   
            });
        },
    },
 
};
</script>